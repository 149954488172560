
import { defineComponent } from "vue-demi";
import Inputs from '@/components/common/inputs/index.vue';

export default defineComponent({
  name: 'ProfilePassword',
  data() {
    return {
      module: {
        data: {
          password: '',
          reset_password: '',
        },
        inputs: [
          { id: 'password', placeholder: 'Введите новый пароль', name: 'Пароль', grid: '1 / 10', type: 'password', drop: false, error: false, required: false, show: true },
          { id: 'reset_password', placeholder: 'Повторите пароль', name: 'Повторите пароль', grid: '1 / 10', type: 'password', drop: false, error: false, required: false, show: true },
        ],
      }
    }
  },
  methods: {
    async changePassword() {
      const result = await this.API.user.changePassword(this.module.data);
      this.$store.commit('updateUser', result.data);
    }
  },
  components: {
    Inputs
  }
})
